
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { historySidebarRoutes } from '@/router/sidebarRoutes/historyRoutes'

export default defineComponent({
  components: { PageContentSidebarList },
  setup() {
    return {
      historySidebarRoutes,
    }
  },
})
